import { gql, useApolloClient } from "@apollo/client";
import React, { useState } from "react";
import { Track } from "../../types/TrackTypes";
import { getCoverArtBlobURL, getTrackBlobURL } from "../../utils/Helpers";
import { useFileDownloader } from "../../utils/useFileDownloader";
import Check from "../Check";
import SectionsList, { SectionListItem } from "../sectionslist/SectionsList";
import ISRCListForm from "../forms/isrclistform/ISRCListForm";
import ContentWrapper from "../ContentWrapper";
import axiosClient from "../../clients/AxiosClient";

const BlobChecker = () => {
  const [productsWithTracks, setproductsWithTracks] = useState<{
    [isrc: string]: number[];
  }>();
  const [tracksOnProducts, settracksOnProducts] = useState<{
    [upc: number]: Track[];
  }>({});
  const fileDownloader = useFileDownloader({ useGlobalState: true });
  const apolloClient = useApolloClient();

  return (
    <ContentWrapper title="Blob checker">
      <ISRCListForm
        submitCB={(isrcs) => {}}
        onParsedISRCs={(isrcs) => {
          isrcs?.forEach((isrc) =>
            fileDownloader.checkFileExist(getTrackBlobURL(isrc))
          );
          apolloClient
            .query<{
              getProductsForTracks: { [isrc: string]: number[] };
            }>({
              query: gql`
                query GetProductsForTracks($isrcs: [String!]!) {
                  getProductsForTracks(isrcs: $isrcs)
                }
              `,
              variables: {
                isrcs: isrcs,
              },
            })
            .then((res) => {
              Object.values(res.data.getProductsForTracks)
                .flat()
                .forEach((upc) => {
                  fileDownloader.checkFileExist(getCoverArtBlobURL(upc));
                  axiosClient
                    .get<Track[]>(`/products/${upc}/tracks`)
                    .then((res) => res.data)
                    .then((tracks) => {
                      tracks.forEach((t) =>
                        fileDownloader.checkFileExist(getTrackBlobURL(t.isrc))
                      );
                      tracksOnProducts[upc] = tracks;
                      settracksOnProducts({
                        ...tracksOnProducts,
                      });
                    });
                });

              setproductsWithTracks(res.data.getProductsForTracks);
            });
        }}
      />

      {productsWithTracks && (
        <React.Fragment>
          {Object.entries(productsWithTracks).map(([isrc, upcs]) => (
            <SectionsList
              className="mt-8 w-1/4"
              title={isrc}
              key={`${isrc}+128`}
            >
              <SectionListItem>
                <div className="grid grid-cols-2 gap-x-8">
                  <p>UPC/ISRC</p>
                  <p>hasBlob</p>
                </div>
              </SectionListItem>
              {upcs.map((upc) => (
                <SectionListItem key={upc}>
                  <div className="grid grid-cols-2 gap-x-8">
                    <p>{upc}</p>
                    <Check
                      value={
                        fileDownloader.existingFiles[getCoverArtBlobURL(upc)]
                      }
                    />
                  </div>
                  <div className="flex flex-col">
                    {tracksOnProducts &&
                      tracksOnProducts[upc]?.map((t) => (
                        <div className="grid grid-cols-2 gap-x-8" key={t.isrc}>
                          <p className="pl-2">{t.isrc}</p>
                          <Check
                            value={
                              fileDownloader.existingFiles[
                                getTrackBlobURL(t.isrc)
                              ]
                            }
                          />
                        </div>
                      ))}
                  </div>
                </SectionListItem>
              ))}
            </SectionsList>
          ))}
        </React.Fragment>
      )}
    </ContentWrapper>
  );
};

export default BlobChecker;
