import React from "react";
import { Outlet } from "react-router";
import AwTab from "./AwTab";

interface ViewMenuWrapperProps {
  children:
    | React.ReactElement<typeof AwTab>[]
    | React.ReactElement<typeof AwTab>;
}

/**
 * How to use:
 *  <AwTabs>
 *   <AwTab to={"/financial/settlements"}>Settlements</AwTab>
 *   <AwTab to={"/financial/settlements/upload"}>Upload</AwTab>
 *  </AwTabs>
 * @param param0
 * @returns
 */
const AwTabs = ({ children }: ViewMenuWrapperProps) => {
  return (
    <div>
      <ul className="flex gap-x-0 mb-4 items-center justify-start">
        {children}
      </ul>

      <Outlet />
    </div>
  );
};

export default AwTabs;
