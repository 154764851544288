import { Form, Formik } from "formik";
import _ from "lodash";
import React from "react";
import SimpleField from "../../SimpleField";

const ISRCListForm = (props: {
  submitCB?: (isrcs: string[]) => void;
  onParsedISRCs?: (isrcs: string[] | undefined) => void;
}) => {
  return (
    <Formik
      initialValues={{ isrcs: [] }}
      enableReinitialize
      onSubmit={(values, helpers) => {
        if (props.submitCB) {
          props.submitCB(values.isrcs);
        }
        helpers.resetForm();
      }}
    >
      {({ setFieldValue, handleSubmit, isSubmitting, dirty }) => (
        <Form className="grid gap-4 w-1/2" onSubmit={handleSubmit}>
          <SimpleField
            type="text"
            disabled={isSubmitting}
            name="isrcs"
            placeholder={"Enter ISRCs separated by comma or space"}
            onChange={(
              value: React.ChangeEvent<HTMLInputElement> | undefined
            ) => {
              if (value) {
                const count = (str: string, char: string) =>
                  _.countBy(str)[char] || 0;
                const isrcString = value?.currentTarget.value || "";

                let delimiter = ",";
                const nrLineSplitRows = count(isrcString, " ");
                const nrCommaSplitRows = count(isrcString, ",");

                if (nrLineSplitRows > nrCommaSplitRows) {
                  delimiter = " ";
                }
                if (isrcString[isrcString.length - 1] === ",") {
                  return;
                }
                const listOfISRCS = value?.currentTarget.value
                  .split(delimiter)
                  .map((isrc: string) => isrc.trim())
                  .filter((isrc) => isrc !== "");
                setFieldValue("isrcs", listOfISRCS);
                props.onParsedISRCs && props.onParsedISRCs(listOfISRCS);
              }
            }}
          />
          <button className="small submit" disabled={!dirty}>
            Add tracks
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ISRCListForm;
