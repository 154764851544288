import React, { ReactNode, useEffect, useState } from "react";
import {
  BASE_URL_PROD,
  BASE_URL_LOCAL,
  IS_DEV_ENVIRONMENT,
} from "../../config/Constants";

interface ConfigProviderProps {
  children: ReactNode;
}

const ConfigContext = React.createContext<{
  baseUrl: string;
  toggleDevData: () => void;
  usingProductionData: boolean;
}>({
  baseUrl: BASE_URL_PROD,
  toggleDevData: () => undefined,
  usingProductionData: false,
});

const ConfigProvider = (props: ConfigProviderProps) => {
  const [baseUrl, setBaseUrl] = useState<string>(
    IS_DEV_ENVIRONMENT ? BASE_URL_LOCAL : BASE_URL_PROD
  );

  const [usingProductionData, setusingProductionData] = useState<boolean>(
    !IS_DEV_ENVIRONMENT
  );

  useEffect(() => {
    const savedBaseUrl = localStorage.getItem("baseUrl");

    if (savedBaseUrl) {
      setBaseUrl(savedBaseUrl);
      setusingProductionData(savedBaseUrl === BASE_URL_PROD);
    }
  }, []);

  const toggleDevData = () => {
    if (baseUrl === BASE_URL_PROD) {
      setBaseUrl(BASE_URL_LOCAL);
      //Save to local storage
      localStorage.setItem("baseUrl", BASE_URL_LOCAL);
      setusingProductionData(false);
    } else {
      setBaseUrl(BASE_URL_PROD);
      localStorage.setItem("baseUrl", BASE_URL_PROD);
      setusingProductionData(true);
    }
  };

  return (
    <ConfigContext.Provider
      value={{
        baseUrl,
        toggleDevData,
        usingProductionData,
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;

export const useConfig = () => React.useContext(ConfigContext);
