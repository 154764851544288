import { Form, Formik } from "formik";
import useMessage from "../../../hooks/useMessage";
import { Publisher } from "../../../types/TrackTypes";
import axiosClient from "../../../clients/AxiosClient";
import SimpleField from "../../SimpleField";
import * as Yup from "yup";
import usePublishers from "../../../hooks/usePublishers";
import { AwSection } from "../../awcomponents/AwSection";

interface PublisherFormProps {
  publisher?: Publisher;
  onSubmitCB: () => void;
}

const PublisherForm = (props: PublisherFormProps) => {
  const { showSuccessMessage, showErrorMessage } = useMessage();
  const { publishers } = usePublishers();
  const { publisher, onSubmitCB } = props;

  const validationSchema = Yup.object().shape({
    publisherName: Yup.string()
      .trim()
      .required("This field is required")
      .lowercase()
      .notOneOf(
        publishers?.map((p: Publisher) => p.publisherName.toLowerCase()) || [],
        "Publisher already exists"
      ),
  });

  return (
    <Formik<Publisher>
      initialValues={publisher || { publisherName: "", id: undefined }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values, helpers) => {
        axiosClient
          .post("/publishers", values)
          .then(() => {
            showSuccessMessage("Success", "Publisher saved successfully");
            onSubmitCB && onSubmitCB();
          })
          .catch((error) => {
            showErrorMessage("Error", error.response.data.message);
          });
      }}
    >
      {({ dirty, isValid }) => (
        <Form className="w-full">
          <AwSection>
            <SimpleField
              className="w-full"
              type="string"
              placeholder="Lasse Kongo"
              label="Publisher name"
              name="publisherName"
            />
          </AwSection>
          <button type="submit" disabled={!dirty || !isValid}>
            Save
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default PublisherForm;
