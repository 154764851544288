import { useState } from "react";
import copyIcon from "../../../assets/icons/copy_icon.svg";

interface CopyToClipboardTextProps {
  text: string;
  listOfStrings?: string[];
  staticIcon?: boolean;
}

const CopyString = (props: CopyToClipboardTextProps) => {
  const { text, listOfStrings, staticIcon } = props;
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    const textToCopy = listOfStrings ? listOfStrings.join("\n") : text;
    navigator.clipboard.writeText(textToCopy).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 400); // Reset copied state after 1 seconds
    });
  };

  return (
    <div className="flex group cursor-pointer">
      <img
        className={`mr-1 ${
          staticIcon
            ? ""
            : "opacity-0 group-hover:opacity-100 transition-opacity duration-150 ease-in"
        } `}
        src={copyIcon}
        alt="copy icon"
      />
      <span
        className="group-hover:text-interactive"
        onClick={(e) => {
          e.stopPropagation();
          copyToClipboard();
        }}
      >
        {isCopied ? (
          <span className="text-interactive transistion-opacity">Copied!</span>
        ) : (
          <span
            className={`${
              staticIcon
                ? ""
                : "inline-block transform -translate-x-5 transition-transform group-hover:translate-x-0"
            }`}
          >
            {text}
          </span>
        )}
      </span>
    </div>
  );
};

export default CopyString;
