import { ReactNode } from "react";

interface ContentWrapperProps {
  title?: string;
  children?: ReactNode;
  className?: string;
}

const ContentWrapper = (props: ContentWrapperProps) => {
  return (
    <main
      className={`h-screen bg-neutral-background ${
        props.className ? props.className : null
      }`}
    >
      <div className={`px-4 sm:px-4 xs:px-0 pt-8 mb-5 ${props.className}`}>
        {props.title ? (
          <h1 className="text-4xl font-semibold mb-5">{props.title}</h1>
        ) : null}
        {props.children}
      </div>
    </main>
  );
};

export default ContentWrapper;
