import { Table } from "@tanstack/react-table";

export type RightClickMenuOption<T> = {
  text: string;
  disabled?: boolean;
  onClick: (table: Table<T>) => void;
};

type GeneralTableRightClickMenuProps<T> = {
  options: RightClickMenuOption<T>[];
  menuPosition: { x: number; y: number };
  onBlur: () => void;
  table: Table<T>;
};

const GeneralTableRightClickMenu = <T,>(
  props: GeneralTableRightClickMenuProps<T>
) => {
  const { options, menuPosition, onBlur, table } = props;
  const calcPosition = {
    top: `${menuPosition.y}px`,
    left: `${menuPosition.x}px`,
  };

  return (
    <div
      className={`absolute whitespace-nowrap mt-2 first:rounded-t-md rounded-b-md shadow-lg border bg-white divide-y divide-gray-200 z-40`}
      style={calcPosition}
      onMouseLeave={(e) => {
        e.stopPropagation();
        onBlur();
      }}
    >
      {options.map((option, i) => (
        <div
          key={i}
          className={` px-4 py-2 text-sm ${
            option.disabled
              ? "disabled"
              : "text-interactive cursor-pointer hover:bg-gray-200 active:bg-neutral-trim active:text-interactive-heavy"
          }`}
          role="menuitem"
          id="menu-item-0"
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!option.disabled) {
              option.onClick(table);
            }
          }}
        >
          {option.text}
        </div>
      ))}
    </div>
  );
};

export default GeneralTableRightClickMenu;
