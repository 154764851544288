import { createRoot } from "react-dom/client";
import App from "./App";
import "./assets/font/gontserrat-font.css";
import "./index.scss";
import "./assets/utils.scss";

const appContainer = document.getElementById("root");
if (!appContainer) {
  throw new Error("Failed to find the root element");
}

const root = createRoot(appContainer);
root.render(<App />);
