import { components } from "react-select";
import { ReactComponent as SearchIcon } from "../assets/icons/search_icon.svg";

export const REACT_SELECT_CUSTOM_STYLE = {
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: "#04856C",
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    color: "#04856C",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: 0,
    boxShadow: "none",
  }),
  menuPortal: (base: any) => ({ ...base, color: "#04856C", zIndex: 9999 }),
};

export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <div className="icon button">
        <SearchIcon />
      </div>
    </components.DropdownIndicator>
  );
};

export const SearchIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <div>
        <SearchIcon />
      </div>
    </components.DropdownIndicator>
  );
};
