import { ReactNode } from "react";
import { addMessage, closeMessage } from "../redux/globalmessages/actions";
import { useDispatch } from "react-redux";
import BlobStatus from "../components/BlobStatus";

const useMessage = () => {
  const dispatch = useDispatch();

  const dispatchMessage = (
    type: "error" | "success" | "info" | "warning",
    title: string,
    displayTime: number | undefined = 5000,
    component: ReactNode,
    id?: string
  ) => {
    dispatch(
      addMessage({
        id: id || Math.random().toString(18).substring(2, 15),
        type: type,
        displayTime: displayTime,
        title: title,
        component: component,
      })
    );
  };

  const showInfoMessage = (
    title: string,
    component: ReactNode,
    displayTime: number = 5000,
    id?: string
  ) => {
    dispatchMessage("info", title, displayTime, component, id);
  };

  const showSuccessMessage = (
    title: string,
    component: ReactNode,
    displayTime: number = 5000,
    id?: string
  ) => {
    dispatchMessage("success", title, displayTime, component, id);
  };

  const showWarningMessage = (
    title: string,
    component: ReactNode,
    displayTime: number = 5000
  ) => {
    dispatchMessage("warning", title, displayTime, component);
  };

  const showErrorMessage = (
    title: string,
    component: ReactNode,
    displayTime: number = 5000
  ) => {
    dispatchMessage("error", title, displayTime, component);
  };

  const showBlobStatus = (title: string, url: string) =>
    dispatch(
      addMessage({
        id: url,
        type: "info",
        title: title,
        component: (
          <BlobStatus
            url={url}
            doneCallback={() => {
              const t = setTimeout(() => {
                dispatch(closeMessage(url));
                clearTimeout(t);
              }, 1500);
            }}
          />
        ),
      })
    );

  return {
    showInfoMessage,
    showSuccessMessage,
    showWarningMessage,
    showErrorMessage,
    showBlobStatus,
  };
};

export default useMessage;
