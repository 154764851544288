import {
  AudioMetadata,
  CoverArt,
  FileMetadata,
  LintedMetadataValue,
  SemiLintedMetadata,
  SheetProvider,
} from "../types/BulkTypes";
import {
  Contributor,
  Publisher,
  PublisherOnTrack,
  Track,
  Writer,
} from "../types/TrackTypes";
import { CategoryWithGenres, QSProvider } from "../types/Types";

import { Artist } from "../types/ProductTypes";
import {
  isLintedMetadataType,
  notNullOrUndefined,
  trimLowerLint,
} from "./Helpers";

export const getValue = <T>(value: LintedMetadataValue<T> | T): T => {
  if (isLintedMetadataType(value)) {
    return (value as any).value as T;
  } else {
    return value as any as T;
  }
};

export const getError = <T>(
  value: LintedMetadataValue<T> | T
): string | undefined => {
  if (isLintedMetadataType(value)) {
    return (value as any).error as string | undefined;
  } else {
    return undefined;
  }
};

export const lintRoyaltyRate = (
  royaltyRate: string
): LintedMetadataValue<string> => {
  if (royaltyRate === "") {
    return { value: "-1", error: "Royalty rate missing" };
  }

  const rr = parseFloat(royaltyRate);

  if (rr > 1.0)
    return { value: rr.toString(), error: "Royalty rate above 100%" };

  return { value: royaltyRate, error: undefined };
};

const createLintedMetadataValue = <T>(
  value: T,
  error?: string
): LintedMetadataValue<T> => ({
  value: value,
  error: error,
});

export const lintUPC = (upc: number): LintedMetadataValue<number> => {
  if (upc.toString().length !== 13 && upc.toString().length !== 12) {
    return createLintedMetadataValue(
      upc,
      "The UPC is not 12 or 13 characters long"
    );
  }
  return createLintedMetadataValue(upc);
};

export const lintExistingTrack = (track: Track): SemiLintedMetadata<Track> => {
  return {
    ...track,
    isrc: lintISRC(cleanISRC(track.isrc)),
    mainGenre: lintMainGenre(track.mainGenre),
    contributors: lintContributors(track.contributors),
    publishers: lintPublishers(track.publishers, [], []),
  };
};

const lintMainGenre = (
  mainGenre: string | undefined | null
): LintedMetadataValue<string> => {
  if (!notNullOrUndefined(mainGenre) || mainGenre === "") {
    return {
      value: mainGenre || "",
      error: "This field is required",
    };
  }

  return {
    value: mainGenre,
    error: undefined,
  };
};

export const lintMetadata = async (
  metadata: FileMetadata,
  providers: QSProvider[],
  categoriesWithGenres: CategoryWithGenres[],
  artists: Artist[],
  publishers: Publisher[],
  writers: Writer[]
) => {
  return {
    upc: lintUPC(metadata.upc),
    isrc: lintISRC(cleanISRC(metadata.isrc)),
    artists: lintArtists(metadata.artists, artists),
    featuredArtists: lintArtists(metadata.featuredArtists, artists),
    albumName: lintAlbumName(metadata.albumName),
    label: lintLabel(metadata.label),
    releaseDate: lintReleaseDate(metadata.releaseDate),
    identifier: lintIdentifier(metadata.identifier),
    genre: lintGenre(metadata.genre),
    qsCategory: lintQsCategory(metadata.qsCategory, categoriesWithGenres),
    qsGenre: lintQsGenre(
      metadata.qsGenre,
      metadata.qsCategory,
      categoriesWithGenres
    ),
    fugaMainSubGenre: lintFugaMainSubGenre(metadata),
    fugaProductArtists: lintFugaProductArtists(metadata.fugaProductArtists),
    coverArtFileName: lintcoverArtFileName(
      metadata.coverArtFileName,
      metadata.coverArt?.file
    ),
    audio: lintAudio(metadata.audio),
    coverArt: lintcoverArtFile(metadata.coverArt),
    audioFileName: lintAudioFileName(
      metadata.audioFileName,
      metadata.audio?.file
    ),
    publishers: lintPublishers(metadata.publishers, publishers, writers),
    providers: lintProviders(metadata.providers, providers),
    contributors: lintContributors(metadata.contributors),
    composers: lintComposers(metadata.composers),
    languageOfMetadata: lintLanguageOfMetadata(metadata.languageOfMetadata),
    audioLanguage: lintAudioLanguage(metadata.audioLanguage),
    lyricists: lintLyricist(metadata.lyricists),
    seqNr: lintSeqNr(metadata.seqNr),
    title: lintTitle(metadata.title),
    royaltyRate: lintRoyaltyRate(metadata.royaltyRate),
    albumVersion: lintAlbumVersion(metadata.albumVersion),
    trackVersion: lintTrackVersion(metadata.trackVersion),
    isCover: lintIsCover(metadata.isCover, metadata.composers),
    spotifyEndDate: lintEndDate(metadata.spotifyEndDate),
  };
};

const lintAlbumVersion = (albumVersion: string | undefined) =>
  createLintedMetadataValue(albumVersion || "");
const lintTrackVersion = (trackVersion: string | undefined) =>
  createLintedMetadataValue(trackVersion || "");

const lintComposers = (composers: string[]): LintedMetadataValue<string[]> => {
  return composers.length > 0
    ? createLintedMetadataValue(composers)
    : createLintedMetadataValue(composers, "Missing Composer");
};

const lintProviders = (
  value: SheetProvider[],
  providers: QSProvider[]
): LintedMetadataValue<SheetProvider[]> => {
  let error: string | undefined = undefined;

  const mappedProviders = value.map((p) => {
    if (isNaN(p.royaltyRate)) {
      error = "Incorrect royalty rate";
    }

    const providerId = providers.find(
      (anyProvider) => anyProvider.name === p.name
    )?.id;
    if (providerId === undefined) {
      error = `Could not find provider: ${p.name}`;
    }
    return {
      name: p.name,
      providerId: providerId,
      royaltyRate: p.royaltyRate,
    };
  });
  return error
    ? { value: mappedProviders, error }
    : { value: mappedProviders, error: undefined };
};

function lintISRC(isrc: string): LintedMetadataValue<string> {
  const regexPattern = /^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$/;
  if (!regexPattern.test(isrc)) {
    return createLintedMetadataValue(isrc, "The ISRC has an invalid format");
  }
  return createLintedMetadataValue(isrc);
}

function lintArtists(
  enteredArtists: string[],
  artists: Artist[]
): LintedMetadataValue<Artist[]> {
  const nonExisting: Artist[] = [];
  const existing: Artist[] = [];
  enteredArtists.forEach((artist_name_string) => {
    const artist = artists.find(
      (_a) => trimLowerLint(_a.artistName) === trimLowerLint(artist_name_string)
    );
    if (artist === undefined) {
      nonExisting.push({
        artistName: artist_name_string,
        restricted: false,
        spotifyArtistUri: undefined,
        conceptArtist: false,
      });
    } else {
      existing.push(artist);
    }
  });
  if (nonExisting.length > 0) {
    return createLintedMetadataValue(
      nonExisting,
      `Artist not found: ${nonExisting.map((a) => a.artistName).join(", ")}`
    );
  }
  return createLintedMetadataValue(existing);
}

function lintAlbumName(albumName: string): LintedMetadataValue<string> {
  return createLintedMetadataValue(albumName);
}

function lintLabel(labelName: string): LintedMetadataValue<string> {
  return createLintedMetadataValue(labelName);
}

function lintReleaseDate(releaseDate: string): LintedMetadataValue<string> {
  return createLintedMetadataValue(releaseDate);
}

function lintIdentifier(identifier: string): LintedMetadataValue<string> {
  if (
    !identifier.startsWith("QL") &&
    !identifier.startsWith("FLUM") &&
    !identifier.startsWith("SCMP") &&
    !identifier.startsWith("AZAP") &&
    !identifier.startsWith("OZZY")
  ) {
    return createLintedMetadataValue(
      identifier,
      "Incorrect prefix for identifier"
    );
  }
  return createLintedMetadataValue(identifier);
}

function lintGenre(genre: string): LintedMetadataValue<string> {
  return createLintedMetadataValue(genre);
}

export function lintQsCategory(
  qsCategory: string,
  categoriesWithGenres: CategoryWithGenres[]
): LintedMetadataValue<string> {
  const isInvalidCategory =
    categoriesWithGenres.find((cat) => {
      return cat.category.categoryName === qsCategory;
    }) !== undefined;

  if (isInvalidCategory === false) {
    return createLintedMetadataValue(
      qsCategory,
      `Category "${qsCategory}" does not exist`
    );
  }
  return createLintedMetadataValue(qsCategory);
}

export function lintQsGenre(
  qsGenre: string,
  qsCategory: string,
  categoriesWithGenres: CategoryWithGenres[]
): LintedMetadataValue<string> {
  const genres = categoriesWithGenres.flatMap((c) => c.genres);
  const isExistingGenre =
    genres.find((g) => g.genreName === qsGenre) !== undefined;

  const isValidGenreAndHasCorrectParent =
    categoriesWithGenres
      .find((cat) => cat.category.categoryName === qsCategory)
      ?.genres.find((genre) => genre.genreName === qsGenre) !== undefined;

  if (isValidGenreAndHasCorrectParent === false) {
    if (isExistingGenre === false) {
      return createLintedMetadataValue(
        qsGenre,
        `Genre "${qsGenre}" does not exist`
      );
    }
    return createLintedMetadataValue(
      qsGenre,
      `Genre "${qsGenre}" does not have "${qsCategory}" as parent`
    );
  }

  return createLintedMetadataValue(qsGenre);
}

function lintFugaMainSubGenre(
  metadata: FileMetadata
): LintedMetadataValue<string> {
  switch (metadata.qsGenre) {
    case "Peaceful Guitar":
    case "Peaceful Piano":
      if (metadata.fugaMainSubGenre !== "Classical Crossover") {
        return createLintedMetadataValue(
          metadata.fugaMainSubGenre,
          "QsGenre is Peaceful, fuga main sub genre should be Classical Crossover"
        );
      }
  }
  return createLintedMetadataValue(metadata.fugaMainSubGenre);
}
function lintFugaProductArtists(
  fugaProductArtists: string[]
): LintedMetadataValue<string[]> {
  return createLintedMetadataValue(fugaProductArtists);
}

function lintcoverArtFileName(
  artWorkFileName: string,
  artWorkFile?: File
): LintedMetadataValue<string> {
  const parts = artWorkFileName.split(".");

  if (parts[parts.length - 1] !== "jpg") {
    return createLintedMetadataValue(
      artWorkFileName,
      "Incorrect filetype for cover art."
    );
  }

  if (artWorkFile === undefined) {
    return createLintedMetadataValue(
      artWorkFileName,
      `Could not find ${artWorkFileName}`
    );
  }

  return createLintedMetadataValue(artWorkFileName);
}

function lintAudio(
  audio: AudioMetadata | undefined
): LintedMetadataValue<AudioMetadata | undefined> {
  if (audio === undefined) {
    return createLintedMetadataValue(audio, "No audio file found");
  }
  return createLintedMetadataValue(audio);
}

const lintcoverArtFile = (coverArt: CoverArt | undefined) => {
  if (coverArt === undefined) {
    return createLintedMetadataValue(coverArt, "No cover art found");
  }

  if (coverArt.dimension > 3200 || coverArt.dimension < 1000) {
    return createLintedMetadataValue(
      coverArt,
      `Cover art dimension is invalid, ${coverArt.dimension}. Must be between 1000px and 3200px`
    );
  }
  return createLintedMetadataValue(coverArt);
};

function lintAudioFileName(
  audioFileName: string,
  audioFile?: File
): LintedMetadataValue<string> {
  if (audioFile === undefined) {
    return createLintedMetadataValue(
      audioFileName,
      `Could not find audio file ${audioFileName}`
    );
  }
  return createLintedMetadataValue(audioFileName);
}

function lintPublishers(
  enteredPublishers: PublisherOnTrack[] | undefined,
  publishers: Publisher[],
  writers: Writer[]
): LintedMetadataValue<PublisherOnTrack[]> {
  const { nonExisting, existing } = getExistingAndNonExistingPublishers(
    enteredPublishers || [],
    publishers,
    writers
  );

  if (nonExisting.length > 0) {
    const nonExistingPublishers = nonExisting
      .filter((pub) =>
        publishers.every(
          (_p) =>
            trimLowerLint(_p.publisherName) !==
            trimLowerLint(pub.publisher.publisherName)
        )
      )
      .map((pub) => pub.publisher.publisherName);

    const nonExistingWriters = nonExisting
      .filter((pub) =>
        writers.every(
          (_w) =>
            trimLowerLint(_w.writerName) !==
            trimLowerLint(pub.writer.writerName)
        )
      )
      .map((pub) => pub.writer.writerName);

    return createLintedMetadataValue(
      nonExisting,
      `Non-existing publishers found - Publishers: ${nonExistingPublishers.join(
        ", "
      )}, Writers: ${nonExistingWriters.join(", ")}`
    );
  }

  return createLintedMetadataValue(existing);
}

function getExistingAndNonExistingPublishers(
  enteredPublishers: PublisherOnTrack[],
  publishers: Publisher[],
  writers: Writer[]
) {
  const nonExisting: PublisherOnTrack[] = [];
  const existing: PublisherOnTrack[] = [];

  enteredPublishers.forEach((pub) => {
    const publisher = publishers.find(
      (_p) =>
        trimLowerLint(_p.publisherName) ===
        trimLowerLint(pub.publisher.publisherName)
    );

    const writer = writers.find(
      (_w) =>
        trimLowerLint(_w.writerName) === trimLowerLint(pub.writer.writerName)
    );

    if (publisher && writer) {
      existing.push({ ...pub, publisher: publisher, writer: writer });
    } else {
      nonExisting.push(pub);
    }
  });

  return { nonExisting, existing };
}

function lintContributors(
  contributors: Contributor[] | undefined
): LintedMetadataValue<Contributor[]> {
  if (
    contributors === undefined ||
    contributors === null ||
    contributors.length < 1
  ) {
    return createLintedMetadataValue([], "No contributors found");
  }

  return createLintedMetadataValue(contributors);
}

function lintLanguageOfMetadata(
  languageOfMetadata: string
): LintedMetadataValue<string> {
  return createLintedMetadataValue(languageOfMetadata);
}

function lintAudioLanguage(audioLanguage: string): LintedMetadataValue<string> {
  return createLintedMetadataValue(audioLanguage);
}

function lintLyricist(lyricists: string[]): LintedMetadataValue<string[]> {
  return createLintedMetadataValue(lyricists);
}

function lintSeqNr(seqNr: number): LintedMetadataValue<number> {
  return createLintedMetadataValue(seqNr);
}

function lintTitle(title: string): LintedMetadataValue<string> {
  return createLintedMetadataValue(title);
}

function lintIsCover(
  isCover: boolean,
  composers: string[]
): LintedMetadataValue<boolean> {
  if (isCover && composers.length === 0) {
    return createLintedMetadataValue(
      isCover,
      "You need to specify the composers of the original song!"
    );
  }

  return createLintedMetadataValue(isCover);
}

function lintEndDate(endDate: string): LintedMetadataValue<string | undefined> {
  if (endDate === "") {
    return createLintedMetadataValue(undefined);
  }
  return createLintedMetadataValue(endDate);
}

function cleanISRC(isrc: string): string {
  return isrc.replace(/[^a-zA-Z0-9]/g, "");
}
