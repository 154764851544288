import { useQuery } from "@tanstack/react-query";
import axiosClient from "../clients/AxiosClient";
import { Track } from "../types/TrackTypes";

const useTrack = (isrc?: string) => {
  const { data, isLoading, error } = useQuery<Track>({
    queryKey: ["getTrack", isrc],
    queryFn: () => axiosClient.get(`/tracks/${isrc}`).then((res) => res.data),
    enabled: !!isrc,
  });

  return {
    track: data,
    isLoading,
    error,
  };
};

export default useTrack;
