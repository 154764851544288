import React, { ReactNode, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavbarListItem, amIActiveAsMenuLink } from "./NavbarListItem";

export interface NavbarLinkProps {
  icon?: ReactNode;
  title: string;
  path: string;
}

export interface NavbarSubMenuProps {
  menuName: string; //for html referencing
  mainLink: {
    icon?: ReactNode;
    title: string;
    path: string;
  };
  linkList: NavbarLinkProps[];
  empty?: boolean;
}

const NavbarSubMenu = React.memo((props: NavbarSubMenuProps) => {
  const { mainLink, linkList } = props;
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [isSubMenuItemActive, setisSubMenuItemActive] = useState(false);

  const deriveIfAnyChildIsActive = () => {
    const isAnyLinkActive = linkList.some((link) =>
      amIActiveAsMenuLink(location, link.path)
    );

    //Current state is going to change, so we need to close the menu
    if (isSubMenuItemActive && !isAnyLinkActive) {
      setIsOpen(false);
    }

    //To cater when page is reload and we are on a sub menu page
    if (!isOpen && isAnyLinkActive) {
      setIsOpen(true);
    }

    setisSubMenuItemActive(isAnyLinkActive);
  };

  useEffect(() => {
    deriveIfAnyChildIsActive();
  }, [location.pathname]);

  return (
    <div className="text-interactive hover:text-interactive-hover cursor-pointer rounded-lg">
      <ul
        className="flex justify-between items-center pr-2 rounded-lg hover:bg-interactive-background"
        onClick={() => {
          if (!isSubMenuItemActive) {
            setIsOpen(!isOpen);
          }
        }}
      >
        {props.empty?.valueOf() ? (
          <EmptyMainLink mainLink={mainLink} />
        ) : (
          <Link
            className="focus:outline-none w-full rounded-lg"
            tabIndex={0}
            to={mainLink.path}
          >
            <li
              className={`flex gap-x-4 mb-2 p-2 first:mb-0 text-interactive hover:text-interactive-hover ${
                amIActiveAsMenuLink(location, mainLink.path)
                  ? "font-semibold"
                  : ""
              }`}
            >
              {mainLink.icon && <span>{mainLink.icon}</span>}
              {mainLink.title}
            </li>
          </Link>
        )}
        <OpenOrClosedIcon isOpen={isOpen} />
      </ul>

      {
        <ul
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          className={`overflow-hidden  ${
            isOpen ? "h-auto" : "h-0"
          } transition-all duration-500`}
        >
          {linkList.map((link) => (
            <NavbarListItem
              key={link.path}
              link={link}
              innerLinkClassName="pl-12 first:rounded-t-lg last:rounded-b-lg hover:bg-neutral-trim transition-all"
            />
          ))}
        </ul>
      }
    </div>
  );
});

export default NavbarSubMenu;

/**
 * Link that is not clickable, but still looks like a link
 * @param props
 * @returns
 */
const EmptyMainLink = (props: { mainLink: NavbarLinkProps }) => (
  <li className="flex gap-x-4 mb-2 p-2 first:mb-0">
    {props.mainLink.icon && <span>{props.mainLink.icon}</span>}{" "}
    {props.mainLink.title}
  </li>
);

const OpenOrClosedIcon = (props: { isOpen: boolean }) => (
  <i
    className={`fas fa-chevron-down transition-transform transform ${
      props.isOpen ? "rotate-180" : "rotate-0"
    } duration-150 ease-in-out`}
  />
);
