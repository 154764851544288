import { Column, Table } from "@tanstack/react-table";

import _ from "lodash";
import React from "react";
import Select from "react-select";

import { REACT_SELECT_CUSTOM_STYLE } from "../../utils/ReactSelectConfig";

export function Filter({
  column,
  table,
}: {
  column: Column<any, any>;
  table: Table<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();
  const { filterVariant } = column.columnDef.meta ?? {};

  const sortedUniqueValues = React.useMemo(() => {
    const values =
      filterVariant === "range"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys())
            .sort()
            .slice(0, 5000);
    // check if key is an array
    if (Array.isArray(values[0])) {
      return _.uniq(values.flatMap((value) => value)).sort();
    }
    return values;
  }, [column.getFacetedUniqueValues(), filterVariant]);

  if (filterVariant) {
    switch (filterVariant) {
      case "select":
        return (
          <select
            onChange={(e) => column.setFilterValue(e.target.value)}
            value={columnFilterValue?.toString()}
            className="w-full rounded-md mt-2 p-2 h-10 bg-white focus:outline-none focus:border-transparent text-sm text-neutral-semiheavy"
          >
            <option value="">All</option>
            {sortedUniqueValues.map((value) => (
              <option value={value} key={value}>
                {value}
              </option>
            ))}
          </select>
        );

      case "react-select":
        return (
          <Select
            className={
              "bg-white px-2 focus-within text-interactive rounded-md mt-2 w-full h-10"
            }
            options={
              sortedUniqueValues.length > 0
                ? sortedUniqueValues
                    .map((value) => ({
                      label: value,
                      value: value,
                    }))
                    .concat({ label: "All", value: "" })
                : []
            }
            onChange={(option) => column.setFilterValue(option?.value)}
            styles={REACT_SELECT_CUSTOM_STYLE}
          />
        );
    }
  }

  return typeof firstValue === "number" ? (
    <div
      className="flex space-x-2 text-neutral-semiheavy"
      onClick={(e) => e.stopPropagation()}
    >
      <input
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            e.target.value,
            old?.[1],
          ])
        }
        placeholder="Min"
        className="w-full rounded-md mt-2 p-2 h-10 bg-white focus:outline-none focus:border-transparent text-neutral-semiheavy"
      />
      <input
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            old?.[0],
            e.target.value,
          ])
        }
        placeholder="Max"
        className="w-full rounded-md mt-2 p-2 h-10 bg-white focus:outline-none focus:border-transparent text-neutral-semiheavy"
      />
    </div>
  ) : (
    <input
      className="w-full rounded-md mt-2 p-2 h-10 bg-white focus:outline-none focus:border-transparent text-sm text-neutral-semiheavy"
      onChange={(e) => column.setFilterValue(e.target.value.trimStart())}
      onClick={(e) => e.stopPropagation()}
      placeholder="Search..."
      type="text"
      value={(columnFilterValue ?? "") as string}
    />
  );
}
