import React from "react";

import { Field, FieldAttributes } from "formik";

export interface SimpleFieldProps {
  type: String;
  name: String;
  value?: String;
  label?: String;
  as?: String;
  onKeyUp?: (variable?: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: {
    (e: any): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  placeholder?: String;
  disabled?: boolean;
  error?: String;
  discrete?: boolean;
  className?: String;
}

const SimpleField = React.memo((props: SimpleFieldProps) => {
  const {
    name,
    label,
    as,
    type,
    placeholder,
    disabled,
    error,
    className,
    onKeyUp,
    onChange,
    onBlur: handleBlur,
    discrete,
  } = props;

  return (
    <div className={`${className ? className : null}`}>
      {label ? (
        <div className="text-regular-body-bold text-neutral-semiheavy mb-2">
          {label}
        </div>
      ) : null}

      <Field
        className="required"
        type={type}
        name={name}
        disabled={disabled}
        placeholder={placeholder || label}
        onKeyUp={onKeyUp}
        onChange={onChange}
        error={error}
        as={as}
      >
        {({
          field, // { name, value, onChange, onBlur }
          form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }: FieldAttributes<any>) => {
          return (
            <>
              <div
                className={`group flex items-center bg-white border ${
                  meta.error ? "border-error" : null
                } px-2 rounded-xl w-full focus-within:shadow-lg focus-within:border-interactive`}
              >
                <input
                  {...field}
                  className={`p-2 w-full bg-white focus:outline-none text-neutral-semiheavy ${
                    disabled ? "text-trim" : ""
                  }`}
                  type={type}
                  placeholder={placeholder ? placeholder : ""}
                  disabled={disabled}
                  onKeyUp={onKeyUp}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    if (onChange) {
                      onChange(e);
                    } else {
                      field.onChange(e);
                    }
                  }}
                  value={
                    type === "number" ? field.value ?? 0 : field.value ?? ""
                  }
                />
              </div>
              {!discrete && meta.error && (
                <div className="pt-2 text-error">{meta.error}</div>
              )}
            </>
          );
        }}
      </Field>
    </div>
  );
});

export default SimpleField;
