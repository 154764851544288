import {
  getDownloadURL,
  uploadBytesResumable,
  UploadMetadata,
} from "firebase/storage";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateBlobStatus } from "../redux/blob/actions";
import { getStorageRefFromURI, sleep } from "./Helpers";

export interface UploadInformation {
  file: File;
  url: string;
  metadata?: UploadMetadata | object;
}

interface BlobUploaderProps {
  useGlobalState: boolean;
}

export const useBlobUploader = (props?: BlobUploaderProps) => {
  const dispatch = useDispatch();

  const [progress, setProgress] = useState<{ [url: string]: number }>({});
  const [flacUrls, setFlacUrls] = useState<string[]>([]);
  const [flacConversionInProgress, setFlacConversionInProgress] =
    useState<boolean>(false);

  const uploadRecursive = async (
    list: UploadInformation[],
    nrOfWorkers?: number
  ) => {
    const blobItem = list.pop();

    if (blobItem === undefined) {
      return 0;
    }

    await upload(blobItem).catch((reason) => {
      throw new Error("Failed uploading");
    });

    if (list.length > 0) {
      if (nrOfWorkers && nrOfWorkers > 1) {
        let promises = [];
        for (let index = 0; index < nrOfWorkers; index++) {
          promises.push(uploadRecursive(list, 1));
        }
        await Promise.all(promises);
      } else {
        await uploadRecursive(list, 1);
      }
    }
    return 0;
  };

  /**
   * Uploads a blob to google cloud with status updates.
   * @param blob the blob to upload
   * @returns url to blob
   */
  const upload = async (blob: UploadInformation): Promise<string> =>
    new Promise((res, rej) => {
      const storageRef = getStorageRefFromURI(blob.url);
      const uploadTask = uploadBytesResumable(
        storageRef,
        blob.file,
        blob.metadata
      );

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const p = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          if (props?.useGlobalState === true) {
            dispatch(updateBlobStatus({ blobName: blob.url, progress: p }));
          }

          progress[blob.url] = p;
          setProgress({ ...progress });
          if (p === 100) {
            res(blob.url);
          }
        },
        (err) => {
          rej(err);
        },
        () => {}
      );
    });

  const checkIfBlobsExists = async (blobs: UploadInformation[]) => {
    const existsMap: { [url: string]: boolean } = {};

    const promises = blobs.map((info) =>
      getDownloadURL(getStorageRefFromURI(info.url))
        .then((downloadURL) => {
          existsMap[info.url] = true;

          return true;
        })
        .catch((err) => false)
    );

    return await Promise.all(promises).then(() => existsMap);
  };

  //TODO why do we have this both in the backend and here. Kill the backend one.
  const checkIfFLACExists = async (flacUrls: string[]) => {
    const existsMap: { [url: string]: boolean } = {};

    const promises = flacUrls.map((url) =>
      getDownloadURL(getStorageRefFromURI(url))
        .then((downloadURL) => {
          existsMap[url] = true;
          return true;
        })
        .catch((err) => false)
    );

    return await Promise.all(promises).then(() => existsMap);
  };

  const waitForFlacConversion = async (flacUrls: string[]) => {
    while (true) {
      const existsMap = await checkIfFLACExists(flacUrls);
      if (Object.values(existsMap).every((v) => v === true)) {
        setFlacConversionInProgress(false);
        await sleep(2000);
        break;
      }
      await sleep(1000);
    }
  };

  const getFlacUrlsFromBlobs = (blobs: UploadInformation[]) => {
    return blobs
      .filter((blob) => blob.file.type === "audio/wav")
      .map((blob) =>
        blob.url
          .replace("gs://aw-tracks-bucket/", "gs://aw-tracks-bucket/flac/")
          .replace(".wav", ".flac")
      );
  };

  return {
    progress,
    uploadRecursive,
    checkIfBlobsExists,
    upload,
    getFlacUrlsFromBlobs,
    flacConversionInProgress,
    waitForFlacConversion,
    setFlacConversionInProgress,
    flacUrls,
    setFlacUrls,
  };
};
