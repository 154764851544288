import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectBlobStatusState } from "../redux/blob/selectors";
import ProgressBar from "./ProgressBar";
import { closeMessage } from "../redux/globalmessages/actions";

interface BlobStatusProps {
  url: string;
  doneCallback?: () => void;
  noBar?: boolean;
}

const BlobStatus = (props: BlobStatusProps) => {
  const { url, doneCallback, noBar } = props;
  const state = useSelector(selectBlobStatusState());
  const dispatch = useDispatch();

  if (doneCallback && state[url] === 100) {
    const timeout = setTimeout(() => {
      dispatch(closeMessage(url));
      clearTimeout(timeout);
    }, 1500);
    doneCallback();
  }

  return <ProgressBar noBar={noBar?.valueOf()} progress={state[url]} />;
};

export default BlobStatus;
