import { Table } from "@tanstack/react-table";

import { Field, Form, Formik } from "formik";
import _ from "lodash";
import { ReactNode } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import {
  BackIcon,
  FastBackIcon,
  FastForwardIcon,
  ForwardIcon,
} from "../../utils/Icons";

interface PaginationProps<T> {
  table: Table<T>;
}

const Pagination = <T extends object>(props: PaginationProps<T>) => {
  const {
    getCanNextPage,
    getCanPreviousPage,
    getPageCount,
    previousPage,
    nextPage,
    getState,
    setPageIndex,
    firstPage,
    lastPage,
    getFilteredRowModel,
  } = props.table;

  const { manualPagination } = props.table.options;

  let [searchParams] = useSearchParams();
  const location = useLocation();

  const navigate = useNavigate();

  const filteredPageCount = manualPagination
    ? getPageCount()
    : _.ceil(
        getFilteredRowModel().rows.length / getState().pagination.pageSize
      );

  const getURLIfSearchQueryProvided = () => {
    const param = searchParams.get("search");
    return param !== null ? `&search=${param}` : "";
  };

  return (
    <div className="flex px-6 py-2 bg-white rounded-full">
      <PaginationButton
        isDisabled={!getCanPreviousPage()}
        onClick={() => {
          firstPage();
          navigate(location.pathname);
        }}
        icon={<FastBackIcon />}
      />

      <PaginationButton
        isDisabled={!getCanPreviousPage()}
        onClick={() => {
          previousPage();
          navigate(
            `${location.pathname}?page=${
              getState().pagination.pageIndex
            }${getURLIfSearchQueryProvided()}`
          );
        }}
        icon={<BackIcon />}
      />

      <div className="text-center flex gap-x-2 justify-center items-center">
        <Formik
          initialValues={{ page: getState().pagination.pageIndex + 1 }}
          enableReinitialize
          onSubmit={(e) => {
            navigate(
              `${location.pathname}?page=${
                e.page
              }${getURLIfSearchQueryProvided()}`
            );
            setPageIndex(e.page - 1);
          }}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Field
                type="text"
                className="border rounded-md focus:outline-none w-10 text-center"
                name="page"
              />
            </Form>
          )}
        </Formik>
        of {filteredPageCount}
      </div>
      <PaginationButton
        isDisabled={!getCanNextPage()}
        onClick={() => {
          nextPage();
          navigate(
            `${location.pathname}?page=${
              getState().pagination.pageIndex + 2
            }${getURLIfSearchQueryProvided()}`
          );
        }}
        icon={<ForwardIcon />}
      />
      <PaginationButton
        isDisabled={!getCanNextPage()}
        onClick={() => {
          navigate(
            `${
              location.pathname
            }?page=${getPageCount()}${getURLIfSearchQueryProvided()}`
          );
          lastPage();
        }}
        icon={<FastForwardIcon />}
      />
    </div>
  );
};

const PaginationButton = (props: {
  isDisabled: boolean;
  onClick: () => void;
  icon: ReactNode;
}) => {
  const { isDisabled, onClick } = props;
  return (
    <span
      className={`text-interactive ${
        isDisabled
          ? "text-neutral-light"
          : "hover:text-interactive-hover cursor-pointer"
      }`}
      onClick={() => {
        if (!isDisabled) {
          onClick();
        }
      }}
    >
      {props.icon}
    </span>
  );
};

export default Pagination;
